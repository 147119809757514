import { mapState } from 'vuex'
import { trackEvent } from '../../../../../plugins/firebase'

export default {
  name: 'StepFinalOthers',
  props: ['report'],

  components: {
    AnonymousMode: () => import('./components/Anonymous'),
    IdentifiedMode: () => import('./components/Identified'),
  },

  data() {
    return {
      copied: false,
      showSnackbar: false,
      copyText: 'copy',
    }
  },

  mounted() {
    trackEvent('send_mode_view_final_step')
    window.scrollTo({ top: -100, behavior: 'smooth' })
  },

  computed: {
    ...mapState(['loading']),

    isReportAnonymous() {
      return this.report.anonymous
    },
  },

  methods: {
    submitAnonymously() {
      this.report.anonymous = true
      this.$emit('submit:reportAnonymous')
      this.submit()
    },

    submitIdentified(contact) {
      this.report.contactEmail = contact
      this.submit()
    },

    submit() {
      this.$emit('submit')
      trackEvent('report_submitted')
    },

    copy() {
      this.showSnackbar = true
      const element = document.getElementById('protocol')
      element.setAttribute('type', 'text')
      element.select()
      trackEvent('copy_code_clicked')

      try {
        document.execCommand('copy')
        this.copyText = 'copied'
      } catch (err) {
        console.log('Oops, unable to copy') // eslint-disable-line no-console
      }

      element.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
  },
}
